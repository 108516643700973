import React from 'react'
import LoadingScreen from './LoadingScreen'
import UserName from '../Auth/UserName'
import UserAccess from '../Auth/UserAccess'

const Homepage = () => {
  var userName = UserName()
  var userAccess = UserAccess()

  if (userName != 'notLoggedInDa') {
    return (
      <div id="page-wrap">
        <br /> <br /> <br /> <br />
        <h1>
          {' '}
          Hi {userName}, Welcome to MERGY, a script handler tool!{' '}
          <span role="img" aria-label="star"></span>{' '}
        </h1>
        <h2> You have {userAccess} access! </h2> <br />
        <h4>
          {' '}
          Check out list of modules in the sidebar menu
          <span role="img" aria-label="star">
            👨‍💻
          </span>
        </h4>
      </div>
    )
  } else {
    return (
      <div>
        <LoadingScreen />
        {<UserName />}
      </div>
    )
  }
}
export default Homepage
