import React from 'react';


const LoadingScreen = () => {


    return (
        <div id="page-wrap">
            <br/> <br/> <br/> <br/>
        <h1> Loading </h1> <br/>
         </div>
        )
};
    

export default LoadingScreen;