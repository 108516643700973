import UserAttributes from "./UserAttributes";

const UserFullName = () => {
  const userAttributes = UserAttributes();

  if (userAttributes != null) {
    const firstName = userAttributes["given_name"];
    const lastName = userAttributes["family_name"];
    console.log("PrintingUseFullName: ", firstName, lastName);
    return firstName + " " + lastName;
  } else {
    console.log("PrintingUserFullName: null");
    return null;
  }
};

export default UserFullName;
