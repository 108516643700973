import React, { useEffect, useState } from 'react'
import Navbar from './components/layout/Navbar'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './style.css'
import './SidebarStyles.css'
import Amplify, { Auth, Hub } from 'aws-amplify'
import Homepage from './components/pages/Home'
import UserNotFound from './components/pages/UserNotFound'
import UserAccess from './components/Auth/UserAccess'
import AccessNotFound from './components/pages/AccessNotFound'
import UserId from './components/Auth/UserId'
import ScriptDashboard from './components/pages/ScriptDashboard'
import AddScriptDetails from './components/pages/AddScriptDetails'
import ViewScriptDetails from './components/pages/ViewScriptDetails'
import DisableScripts from './components/pages/DisableScripts'
import TeamHandler from './components/pages/TeamHandler'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: 'us-east-1_Q8d1lhsxp', //NASA
    userPoolId: 'us-east-1_xxWUK1Sor', //CopsHome
    // userPoolId: 'us-east-1_iRXHZW9cK', //Aquiline

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: '5bsdlb9qbqcj6ogptv13c11ak5', //NASA-Aq
    // userPoolWebClientId: '7coqm9rnla4adqus6uvqn5e4om', //NASA-Dash
    userPoolWebClientId: '4s8eb0qsnrdolnq6ofsugr05t', //CopsHome_NoSecret
    // userPoolWebClientId: 'rlf3140rlumcmtpvc3nsu1jkc', //Aquiline_AppClient
    // userPoolWebClientId: 'ung6rud95p32m4esjhps1p4bi', //Aquiline_NoSecret

    // OPTIONAL - Hosted UI configuration
    oauth: {
      // domain: 'nasadash.auth.us-east-1.amazoncognito.com', //NASA
      domain: 'copshomefederate.auth.us-east-1.amazoncognito.com', //CopsHome
      // domain: 'aquilinefederate.auth.us-east-1.amazoncognito.com', //Aquiline
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      // redirectSignIn: 'https://beta.aquiline.cops.alexa.a2z.com',
      // redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      // redirectSignIn: 'https://aquiline-react-static.s3.amazonaws.com/index.html',
      redirectSignOut:
        'https://aquiline-react-static.s3.amazonaws.com/login/oauth2/code/cognito',
      responseType: 'code', // REFRESH token will only be generated when the responseType is code
    },
  },
})

function App() {
  const [user, setUser] = useState(null)
  const currentUser = UserId()
  let [userData, setUserData] = useState(null)

  useEffect(() => {
    getUser().then((userData) => setUserData(userData))
  }, [])

  function getUser() {
    return Auth.currentAuthenticatedUser()

      .then((userData) => console.log('userinfoAppJs', userData))
      .catch(() => Auth.federatedSignIn())
  }

  const accessLevel = UserAccess()
  console.log('Access Level', accessLevel)
  if (accessLevel === 'unknown') {
    return (
      <div className="App">
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={UserNotFound} />
              <Route exact path="/Home" component={UserNotFound} />
              <Route exact path="/scriptDashboard" component={UserNotFound} />
              <Route
                exact
                path="/disabledListOfScript"
                component={UserNotFound}
              />
              <Route exact path="/addScriptDetails" component={UserNotFound} />
              <Route
                exact
                path="/viewScriptDetails/:scriptName"
                component={UserNotFound}
              />
              <Route exact path="/teamHandler" component={UserNotFound} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  } else if (accessLevel === 'Tester') {
    return (
      <div className="App">
        <Router>
          <div className="App">
            <Navbar />
            {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
            <Switch>
              <Route exact path="/" component={UserNotFound} />
              <Route exact path="/Home" component={UserNotFound} />
              <Route exact path="/scriptDashboard" component={UserNotFound} />
              <Route
                exact
                path="/disabledListOfScript"
                component={UserNotFound}
              />
              <Route exact path="/addScriptDetails" component={UserNotFound} />
              <Route
                exact
                path="/viewScriptDetails/:scriptName"
                component={UserNotFound}
              />
              <Route exact path="/teamHandler" component={UserNotFound} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  } else {
    return (
      <div className="App">
        <Router>
          <div className="App">
            <Navbar />
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/Home" component={Homepage} />
              <Route
                exact
                path="/scriptDashboard"
                component={ScriptDashboard}
              />
              <Route
                exact
                path="/disabledListOfScript"
                component={DisableScripts}
              />
              <Route
                exact
                path="/addScriptDetails"
                component={AddScriptDetails}
              />
              <Route
                exact
                path="/viewScriptDetails/:scriptName"
                component={ViewScriptDetails}
              />
              <Route exact path="/teamHandler" component={TeamHandler} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}

export default App
