import React from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import swal from 'sweetalert'
import UserId from '../Auth/UserId'
import swal2 from 'sweetalert2'
import Multiselect from 'multiselect-react-dropdown'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

class ViewScriptDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scriptDetails: {},
      userSelectedTeams: [],
      scriptName: '',
      urlPattern: [],
      description: '',
      selectedTeam: [],
      selectedValue: [],
      createdOn: 0,
      createdBy: '',
      modifiedOn: 0,
      modifiedBy: '',
      scriptOwner: '',
      status: true,
      uiStatus: '',
      defaultSelect: '',
      apiResponse: 0,
      listOfTeam: [],
      version: 0,
      submitDisabled: true,
      disableSavebtn: true,
      prevDescription: '',
      prevUrlPatterns: [],
      prevSelectedTeam: [],
    }
    this.onSelect = this.onSelect.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.multiselectRef = React.createRef()
    this.inputRef = React.createRef()
  }

  //Duplicate check at the time of no change
  compareObject(oldScript, updatedScript) {
    let oldScriptData = {
      status: oldScript.status,
      list_of_pattern: oldScript.list_of_pattern,
      entitlement_rules: oldScript.entitlement_rules,
      script_owner: oldScript.script_owner,
      version: oldScript.version,
      description: oldScript.description,
    }

    let updatedScriptData = {
      status: updatedScript.status,
      list_of_pattern: updatedScript.list_of_pattern,
      entitlement_rules: updatedScript.entitlement_rules,
      script_owner: updatedScript.script_owner,
      version: updatedScript.version,
      description: updatedScript.description,
    }

    return _.isEqual(oldScriptData, updatedScriptData)
  }

  //Get script properties by scriptName
  componentDidMount() {
    console.log('POP UP CONFIG NAME ' + this.props.data)
    const scriptNameTemp = this.props.match.params.scriptName
    console.log('Script Name from proprs', scriptNameTemp)
    axios
      .get(
        process.env.REACT_APP_MERGY_BACKEND +
          'getScriptDetailsByName/' +
          scriptNameTemp
      )
      .then((res) => {
        this.setState({
          scriptDetails: res.data,
          scriptName: res.data.script_name,
          urlPattern: res.data.list_of_pattern,
          selectedTeam: this.getFormatedData(res.data.entitlement_rules),
          createdBy: res.data.created_by,
          createdOn: res.data.created_date,
          modifiedBy: res.data.modified_by,
          modifiedOn: res.data.modified_date ? res.data.modified_date : 0,
          status: res.data.status,
          uiStatus: res.data.status,
          scriptOwner: res.data.script_owner,
          version: res.data.version,
          description: res.data.description ? res.data.description : '',
          prevDescription: res.data.description ? res.data.description : '',
          prevUrlPatterns: res.data.list_of_pattern,
          prevSelectedTeam: this.getFormatedData(res.data.entitlement_rules),
        })
        console.log('Ch', res.data, res.data.version)
      })
    this.getListOfTeam()
  }

  //getting list of Teams from API
  getListOfTeam() {
    console.log('POP UP CONFIG NAME ' + this.props.data)
    axios
      .get(process.env.REACT_APP_MERGY_BACKEND + 'getListOfTeamNames')
      .then((res) => {
        const options = this.getFormatedData(res.data)
        console.log(options)
        this.setState({
          listOfTeam: options,
        })
        console.log(res.data)
      })
  }

  //util function to create options from api response
  getFormatedData = (options) => {
    let result = []

    for (let i = 0; i < options.length; i++) {
      result.push({
        label: options[i],
        value: options[i],
      })
    }
    return result
  }

  //date conversion
  convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('')
  }

  //File upload function
  async fileUpload(files) {
    const API_PRESIGNED_URL =
      process.env.REACT_APP_MERGY_BACKEND +
      'getPresignedFileUrl/' +
      files[0].name

    let f = files[0]
    console.log('File', files[0])
    console.log('File', files[0].name)
    console.log(f['File'])
    console.log('API URL ', API_PRESIGNED_URL)

    /*GET request : presigned URLs*/

    const getPreSignedUrl = await axios({
      method: 'GET',
      url: API_PRESIGNED_URL,
    })

    console.log('URL from GET ', getPreSignedUrl)

    /*PUT request : upload file to s3*/
    try {
      const result = await fetch(getPreSignedUrl.data.uploadURL, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/javascript',
        },
        body: f,
      })
      console.log('Result', result)
      if (result.error) {
        throw new Error('Error!')
      }
      swal({
        text: 'You have successfully updated script details',
        type: 'success',
      }).then((okay) => {
        if (okay) {
          window.location.href =
            process.env.REACT_APP_REDIRECT_URL + 'scriptDashboard'
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  //Click event handler for save button
  onSubmitRequest = async (e) => {
    const { scriptDetails } = this.state
    const currentUser = UserId()
    const currentDate = Date()
    let dateInMDYFormat = this.convertDate(currentDate)
    let selectedTeamValue = this.state.selectedTeam.map(function (obj) {
      return obj.value
    })

    let requestBody = {
      script_name: this.state.scriptName,
      list_of_pattern: this.state.urlPattern,
      entitlement_rules: selectedTeamValue,
      status: this.state.uiStatus,
      version: this.state.version,
      created_by: this.state.createdBy,
      created_date: parseInt(this.state.createdOn, 10),
      modified_by: currentUser,
      modified_date: parseInt(dateInMDYFormat, 10),
      script_owner: this.state.scriptOwner,
      description: this.state.description,
    }
    console.log(requestBody)

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        script_name: this.state.scriptName,
        list_of_pattern: this.state.urlPattern,
        entitlement_rules: selectedTeamValue,
        description: this.state.description,
        status: this.state.uiStatus,
        version: this.state.version + 1,
        created_by: this.state.createdBy,
        created_date: parseInt(this.state.createdOn, 10),
        modified_by: currentUser,
        modified_date: parseInt(dateInMDYFormat, 10),
        script_owner: this.state.scriptOwner,
      }),
    }

    swal2
      .fire({
        title: 'Are you sure?',
        text: 'This will update script properties into the repository ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(
            process.env.REACT_APP_MERGY_BACKEND + 'addScriptDetails',
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              this.setState({ apiResponse: data.statusCode })
              if (this.state.urlPattern.length <= 0) {
                swal('Error!!', 'Please enter script url pattern :)', 'error')
              } else if (this.state.selectedTeam.length <= 0) {
                swal('Error!!', 'Please select team for the script :)', 'error')
              } else if (
                this.compareObject(scriptDetails, requestBody) &&
                !e.target.files
              ) {
                swal(
                  'Error!!',
                  'There are no chages to update. Please do some changes or upload modified script :)',
                  'error'
                )
              } else if (e.target.files) {
                let fileName = e.target.files[0].name.toLowerCase()
                var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/
                if (
                  format.test(e.target.files[0].name.replace(/\.[^/.]+$/, ''))
                ) {
                  // eslint-disable-next-line no-restricted-globals
                  let userConfirmation = confirm(
                    'File Name contain special character!'
                  )
                  if (userConfirmation == true) {
                    this.inputRef.current.value = ''
                  } else {
                    this.inputRef.current.value = ''
                  }
                }
                if (
                  this.state.scriptName.toLowerCase() !==
                  fileName.replace(/\.[^.$]+$/, '')
                ) {
                  swal('Error!!', 'File Name mismatch', 'error')
                } else {
                  this.fileUpload(e.target.files)
                }
              } else {
                swal({
                  text: 'You have successfully updated script details',
                  type: 'success',
                }).then((okay) => {
                  if (okay) {
                    window.location.href =
                      process.env.REACT_APP_REDIRECT_URL + 'scriptDashboard'
                  }
                })
              }
            })
        }
      })
  }
  //Oncahnge handler for url patterns
  handleChangePattern(event) {
    let flag = event.target.value ? true : false
    let patternList = this.state.urlPattern
    let listOfPattern = event.target.value
    console.log('pattern', listOfPattern)
    patternList = listOfPattern.split(',')
    this.setState({ urlPattern: patternList, submitDisabled: !flag })
  }
  //Oncahnge handler for script owner
  handleChangeScriptOwner(event) {
    let flag = event.target.value ? true : false
    let updatedScriptOwner = event.target.value
    this.setState({ scriptOwner: updatedScriptOwner, submitDisabled: !flag })
  }
  //Oncahnge handler for description
  handleChangeDesc(event) {
    let flag = event.target.value ? true : false
    let updatedDesc = event.target.value
    this.setState({ description: updatedDesc, submitDisabled: !flag })
  }
  //Oncahnge handler for status
  handleChange(event) {
    //     let updatedStatus;
    let flag = event.target.checked ? false : true
    let updatedStatus = event.target.checked
    let uiStatusTemp = event.target.checked ? 'active' : 'inactive'
    this.setState({
      status: updatedStatus,
      submitDisabled: !flag,
      uiStatus: uiStatusTemp,
    })
  }
  //Oncahnge handler for add options from dropdown
  onSelect(selectedList, selectedItem) {
    let flag = selectedList.length != 0 ? true : false

    const { selectedTeam } = this.state
    let selectedObj = selectedTeam
    this.setState({ selectedTeam: selectedList, submitDisabled: !flag })
  }
  //Oncahnge handler for remove options from dropdown
  onRemove(selectedList, removedItem) {
    let flag = removedItem.length != 0 ? true : false
    console.log('List', selectedList)
    console.log('Items', removedItem)
    this.setState({ selectedTeam: selectedList, submitDisabled: !flag })
  }
  //On Discard value reset to existing
  onDiscard = (e) => {
    const {
      description,
      urlPattern,
      prevDescription,
      prevSelectedTeam,
      prevUrlPatterns,
      selectedTeam,
    } = this.state
    console.log('In Discard')
    this.setState({
      description: prevDescription,
      urlPattern: prevUrlPatterns,
      selectedTeam: prevSelectedTeam,
    })
    // this.multiselectRef.current.resetSelectedValues()
    this.inputRef.current.value = ''
  }

  render() {
    const {
      scriptDetails,
      scriptName,
      urlPattern,
      defaultSelect,
      selectedTeam,
      listOfTeam,
      createdOn,
      createdBy,
      modifiedOn,
      modifiedBy,
      status,
      scriptOwner,
      version,
      description,
    } = this.state

    console.log('Pattern', urlPattern)
    console.log('SelectedList', selectedTeam)
    console.log('Version', version)

    return (
      <div className="AddConfigs">
        <div className="name">
          <h3 className="headerInline">{scriptName.toLocaleUpperCase()}</h3>
          <div className="button headerInline headerMargin textSizeHeader">
            <button
              className="link-align disable-styling button-styling"
              onClick={this.onDiscard}
            >
              Discard
            </button>
            <button
              className="link-align enable-styling button-styling"
              onClick={this.onSubmitRequest}
              disabled={this.state.submitDisabled}
            >
              Save
            </button>
          </div>
        </div>
        <div className="custom-control custom-switch right">
          {/* <div > */}
          <input
            type="checkbox"
            checked={this.state.status}
            value={this.state.status}
            onChange={(event) => this.handleChange(event)}
            className="custom-control-input headerInline"
            id="customSwitch1"
          />
          <label
            className="custom-control-label addNewStyling textSizeHeader headerInline"
            for="customSwitch1"
          >
            {this.state.status ? 'Active' : 'InActive'}
          </label>
        </div>
        <hr />
        <br />
        <form>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                Description:
              </span>
            </div>
            <textarea
              data-tip
              data-for="descriptionTip"
              onChange={(event) => this.handleChangeDesc(event)}
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              maxlength="256"
              placeholder="Provide a summary on what the script does"
              aria-label="With textarea"
              value={description}
            ></textarea>
          </div>
          <ReactTooltip id="descriptionTip" place="top" effect="solid">
            The maximum length of a description is 256 characters
          </ReactTooltip>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                URL Patterns:
              </span>
            </div>
            <textarea
              data-tip
              data-for="urlPatternTip"
              onChange={(event) => this.handleChangePattern(event)}
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Enter List of URL patterns separated by comma"
              aria-label="With textarea"
              value={urlPattern}
            ></textarea>
            <ReactTooltip id="urlPatternTip" place="top" effect="solid">
              Enter domain name mentioned as @match inside
              script[domain1,domain2]
            </ReactTooltip>
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                Entitled Teams:
              </span>
              <Multiselect
                // className="input-group"
                style={this.style}
                displayValue="value"
                options={listOfTeam}
                showCheckbox
                onSelect={this.onSelect}
                onRemove={this.onRemove}
                showCheckbox
                selectedValues={selectedTeam}
                placeholder=""
                ref={this.multiselectRef}
                style={{
                  multiselectContainer: {
                    color: 'black',
                  },
                  inputField: {
                    // To change input field position or margin
                    margin: '5px',
                    width: '100%',
                  },
                  searchBox: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    width: '922px',
                    height: '46px',
                  },
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                Script Owner:
              </span>
            </div>
            <textarea
              disabled
              onChange={(event) => this.handleChangeScriptOwner(event)}
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Provide the amazon alias on who owns the script"
              aria-label="With textarea"
              value={scriptOwner}
            ></textarea>
            &nbsp;&nbsp;
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                version:
              </span>
            </div>
            <textarea
              disabled
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Enter Version"
              aria-label="With textarea"
              value={version}
            ></textarea>
          </div>
          <br />
          <input
            className="Center"
            accept=".js"
            type="file"
            ref={this.inputRef}
            onChange={this.onSubmitRequest}
          />
        </form>
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        <hr />
        <div className="container">
          <div className="row1">
            {/* {Coloumn 1} */}
            <div className="col2 textSizeHeader addNewStyling">
              <h9>CreatedBy: {createdBy}</h9>
              <br></br>
              <h9>
                CreatedOn: {createdOn.toString().substring(6, 8)}-
                {createdOn.toString().substring(4, 6)}-
                {createdOn.toString().substring(0, 4)}
              </h9>
            </div>
            {/* {Coloumn 2} */}
            <div className="col3 textSizeHeader addNewStyling custom-control">
              <h9>ModifiedBy: {modifiedBy}</h9>
              <br></br>
              <h9>
                ModifiedOn:
                {modifiedOn && modifiedOn.toString().substring(6, 8)}-
                {modifiedOn.toString().substring(4, 6)}-
                {modifiedOn.toString().substring(0, 4)}
              </h9>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(ViewScriptDetails)
