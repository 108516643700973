import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import swal2 from 'sweetalert2'

const ScriptDashboard = () => {
  const [listOfScript, setListOfScript] = useState([])
  const [listOfTeamName, setListOfTeamName] = useState([])
  const [newTeamName, setNewTeamName] = useState('')
  const [duplicateError, setDuplicateError] = useState('')
  const [apiResponse, setApiResponse] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [search, setSearch] = useState('')
  const [filteredScriptName, setFilteredScriptName] = useState([])

  useEffect(() => {
    loadListOfScripts()
  }, [])

  useEffect(() => {
    setFilteredScriptName(
      listOfScript.filter(
        (item, index) =>
          item.script_name
            .toLowerCase()
            .toString()
            .indexOf(search.toLowerCase()) > -1 ||
          item.description
            .toLowerCase()
            .toString()
            .indexOf(search.toLowerCase()) > -1 ||
          item.entitlement_rules
            .toString()
            .toLowerCase()
            .toString()
            .indexOf(search.toLowerCase()) > -1 ||
          item.status.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (item.script_owner &&
            item.script_owner.toLowerCase().indexOf(search.toLowerCase()) >
              -1) ||
          item.modified_by.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    )
  }, [search, listOfScript])

  //get all active list of scripts
  const loadListOfScripts = async () => {
    const result = await axios.get(
      process.env.REACT_APP_MERGY_BACKEND + 'getAllActiveScript'
    )
    let modifiedListOfScript = result.data.body.Items.map((item, index) => {
      return {
        script_name: result.data.body.Items[index].script_name
          ? result.data.body.Items[index].script_name
          : '',
        list_of_pattern: result.data.body.Items[index].list_of_pattern
          ? result.data.body.Items[index].list_of_pattern
          : '',
        entitlement_rules: result.data.body.Items[index].entitlement_rules
          ? result.data.body.Items[index].entitlement_rules
          : '',
        script_owner: result.data.body.Items[index].script_owner
          ? result.data.body.Items[index].script_owner
          : '',
        status: result.data.body.Items[index].status
          ? result.data.body.Items[index].status
          : '',
        created_by: result.data.body.Items[index].created_by
          ? result.data.body.Items[index].created_by
          : '',
        created_date: result.data.body.Items[index].created_date
          ? result.data.body.Items[index].created_date
          : '',
        modified_by: result.data.body.Items[index].modified_by
          ? result.data.body.Items[index].modified_by
          : '',
        modified_date: result.data.body.Items[index].modified_date
          ? result.data.body.Items[index].modified_date
          : '',
        version: result.data.body.Items[index].version
          ? result.data.body.Items[index].version
          : '',
        description: result.data.body.Items[index].description
          ? result.data.body.Items[index].description
          : '',
      }
    })
    setListOfScript(modifiedListOfScript)
  }

  console.log('result', listOfScript)

  return (
    <div className="container">
      <div className="py-4">
        <h3>Active Scripts</h3>
        <div className="col-sm-3 col-md-3 pull-right">
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          <input
            name="search"
            type="text"
            id="search"
            className="search-box-container search-box pull-right"
            placeholder="Search here...          🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="container">
          <div className="py-4 overflow">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Script</th>
                  <th>Description</th>
                  <th>Entitled Teams</th>
                  <th>Status</th>
                  <th>Script Owner</th>
                  <th>Last Modified By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredScriptName.map((item, index) => (
                  <tr align="left">
                    <td>{index + 1}</td>
                    <td>{item.script_name}</td>
                    <td>{item.description}</td>
                    <td>
                      {item.entitlement_rules.map((childItem) => childItem) +
                        ','}
                    </td>
                    <td>{item.status}</td>
                    <td>{item.script_owner}</td>
                    <td>{item.modified_by}</td>
                    <td align="left">
                      <Link
                        className="link-align view-styling"
                        to={`/viewScriptDetails/${item.script_name}`}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ScriptDashboard
