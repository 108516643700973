import { Auth } from "aws-amplify";

var dummyUser = null;

const User = () => {
  if (dummyUser != null) {
    console.log("PrintingUserdummy: null", dummyUser);
    return dummyUser;
  }

  const user = Auth.user;

  if (user != null) {
    console.log("PrintingUser: ", user);
    dummyUser = user;
    return user;
  } else {
    console.log("PrintingUser: null", user);
    return null;
  }
};

export default User;
