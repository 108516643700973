import React from 'react'
import UserFullName from '../Auth/UserFullName'
import UserId from '../Auth/UserId'
import { Auth } from 'aws-amplify'
import Sidebar from './Sidebar'
import img from '../layout/index.png'

const Navbar = () => {
  const userId = UserId()
  return (
    <nav className="navbar navbar-expand-lg ">
      <div name="container">
        <div className="collapse navbar-collapse" id="main_navbar">
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'App'} />
          <ul className="nav navbar-nav ml-auto"></ul>
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <a className="navbar-brand aquiline-logo" href="/">
                <img className="navbar-icon" src={img} /> MERGY
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav mr-auto"></ul>
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <span className="navbar-user">
                <UserFullName />
              </span>{' '}
              &nbsp;
              <img
                className="card-img-top"
                src={
                  'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' +
                  userId
                }
                alt="Card image cap"
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
