import React from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import swal from 'sweetalert'
import UserId from '../Auth/UserId'
import swal2 from 'sweetalert2'
import Multiselect from 'multiselect-react-dropdown'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

class AddScriptDetails extends React.Component {
  constructor(props) {
    super()

    this.state = {
      listOfTeam: [],
      scriptName: '',
      description: '',
      scriptOwner: '',
      urlPattern: [],
      userSelectedTeams: [],
      selectedValue: [],
      status: 'active',
      defaultSelect: '',
      apiResponse: 0,
      submitDisabled: true,
      listOfScript: [],
      fileName: '',
      files: [],
    }
    this.onSelect = this.onSelect.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.multiselectRef = React.createRef()
    this.inputRef = React.createRef()
  }
  //get list of available teams
  componentDidMount() {
    this.getListOfScript()

    console.log('POP UP CONFIG NAME ' + this.props.data)
    axios
      .get(process.env.REACT_APP_MERGY_BACKEND + 'getListOfTeamNames')
      .then((res) => {
        const options = this.getFormatedData(res.data)
        console.log(options)
        this.setState({
          listOfTeam: options,
        })
        console.log(res.data)
      })
  }
  //get all active list
  getListOfScript() {
    axios
      .get(process.env.REACT_APP_MERGY_BACKEND + 'getAllActiveScript')
      .then((res) => {
        this.setState({
          listOfScript: res.data.body.Items,
        })
        console.log(res.data)
      })
  }
  //checks for already present script
  preventEdit(listOfScript, fileName) {
    let presentScript = false
    for (let i = 0; i < listOfScript.length; i++) {
      if (listOfScript[i].script_name === fileName) {
        presentScript = true
        break
      }
    }
    return presentScript
  }
  //duplicate check for script properties
  duplicateCheck(listOfScript, newScript) {
    let duplicateFlag = false
    for (let i = 0; i < listOfScript.length; i++) {
      let formatedScriptData = {
        script_name: listOfScript[i].script_name,
        list_of_pattern: listOfScript[i].list_of_pattern,
        entitlement_rules: listOfScript[i].entitlement_rules,
        status: listOfScript[i].status,
        script_owner: listOfScript[i].script_owner,
      }
      if (_.isEqual(formatedScriptData, newScript)) {
        duplicateFlag = true
        break
      }
    }
    return duplicateFlag
  }
  //util function to create options from api response
  getFormatedData = (options) => {
    let result = []

    for (let i = 0; i < options.length; i++) {
      result.push({
        label: options[i],
        value: options[i],
      })
    }
    return result
  }
  //Event handler for description
  handleChangeDesc(event) {
    let flag = event.target.value ? true : false
    let descObj = this.state.description
    descObj = event.target.value
    this.setState({ description: descObj, submitDisabled: !flag })
  }
  //Event handler for script owner
  handleChangeScriptOwner(event) {
    let flag = event.target.value ? true : false
    let nameObj = this.state.scriptOwner.toLowerCase()
    nameObj = event.target.value
    this.setState({ scriptOwner: nameObj, submitDisabled: !flag })
  }
  //Event handler for file
  onChange(event) {
    switch (event.target.name) {
      // Updated this
      case 'selectedFile':
        if (event.target.files.length > 0) {
          var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/
          if (
            format.test(event.target.files[0].name.replace(/\.[^/.]+$/, ''))
          ) {
            // eslint-disable-next-line no-restricted-globals
            let userConfirmation = confirm(
              'File Name contain special character!'
            )
            if (userConfirmation == true) {
              this.inputRef.current.value = ''
            } else {
              this.inputRef.current.value = ''
            }
          } else {
            event.target.files[0].name.replace(/\.[^/.]+$/, '')
            // Accessed .name from file
            this.setState({
              fileName: event.target.files[0].name.replace(/\.[^/.]+$/, ''),
              files: event.target.files,
            })
          }
        }
        break
      default:
        this.setState({ [event.target.name]: event.target.value })
    }
  }
  //Event handler for pattern
  handleChangePattern(event) {
    let flag = event.target.value ? true : false
    let patternList = this.state.urlPattern
    let listOfPattern = event.target.value
    console.log('pattern', listOfPattern)
    patternList = listOfPattern.split(',')
    this.setState({ urlPattern: patternList, submitDisabled: !flag })
  }
  //Event handler for selected options
  onSelect(selectedList, selectedItem) {
    let flag = selectedList.length != 0 ? true : false
    console.log('List', selectedList)
    console.log('Items', selectedItem)
    let selectedObj = selectedList

    this.setState({ userSelectedTeams: selectedObj, submitDisabled: !flag })
  }
  //Event handler for removed options
  onRemove(selectedList, removedItem) {
    let flag = removedItem.length != 0 ? true : false
    console.log('List', selectedList)
    console.log('Items', removedItem)
    this.setState({ userSelectedTeams: selectedList, submitDisabled: !flag })
  }
  //util function to covet data
  convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('')
  }
  //Event handler for discard button to reset all input
  onDiscard = (e) => {
    const {
      listOfTeam,
      urlPattern,
      defaultSelect,
      userSelectedTeams,
      scriptOwner,
    } = this.state
    console.log('In Discard')
    this.setState({
      description: '',
      scriptOwner: '',
      urlPattern: [],
      selectedValue: [],
    })
    this.multiselectRef.current.resetSelectedValues()
    this.inputRef.current.value = ''
  }
  ///File upload function
  async fileUpload(files) {
    const API_PRESIGNED_URL =
      process.env.REACT_APP_MERGY_BACKEND +
      'getPresignedFileUrl/' +
      files[0].name

    let f = files[0]
    console.log('File', files[0])
    console.log('File', files[0].name)
    console.log(f['File'])
    console.log('API URL ', API_PRESIGNED_URL)

    /*GET request : presigned URLs*/

    const getPreSignedUrl = await axios({
      method: 'GET',
      url: API_PRESIGNED_URL,
    })

    console.log('URL from GET ', getPreSignedUrl)

    /*PUT request : upload file to s3*/
    try {
      const result = await fetch(getPreSignedUrl.data.uploadURL, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/javascript',
        },
        body: f,
      })
      console.log('Result', result)
      if (result.error) {
        throw new Error('Error!')
      }
      swal({
        text: 'You have successfully added script details',
        type: 'success',
      }).then((okay) => {
        if (okay) {
          window.location.href =
            process.env.REACT_APP_REDIRECT_URL + 'scriptDashboard'
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
  //Click event handler for save button
  onSubmitRequest = async (e) => {
    const { listOfScript, files, fileName } = this.state

    const currentUser = UserId()
    const date = Date()
    let dateMDY = this.convertDate(date)

    let requestBody = {}
    console.log('description', this.state.description)
    console.log('URL Pattern', this.state.urlPattern)
    console.log('Current User', currentUser)
    console.log('Selected Team', this.state.userSelectedTeams)
    console.log('CurrentDate', parseInt(dateMDY, 10))

    let selectedTeamValue = this.state.userSelectedTeams.map(function (obj) {
      return obj.value
    })

    console.log('Selected Team Value', selectedTeamValue)

    let reqBody = {
      script_name: this.state.fileName,
      list_of_pattern: this.state.urlPattern,
      entitlement_rules: selectedTeamValue,
      status: this.state.status,
      script_owner: this.state.scriptOwner,
    }

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        script_name: this.state.fileName,
        list_of_pattern: this.state.urlPattern,
        entitlement_rules: selectedTeamValue,
        status: this.state.status,
        version: 0,
        created_by: currentUser,
        script_owner: this.state.scriptOwner,
        description: this.state.description,
        created_date: parseInt(dateMDY, 10),
        modified_by: null,
        modified_date: null,
      }),
    }

    console.log(requestOptions)
    console.log('1st', listOfScript, '2nd', reqBody)
    let duplicateCheck = this.duplicateCheck(listOfScript, reqBody)
    let preventEditScript = this.preventEdit(listOfScript, this.state.fileName)
    if (!preventEditScript) {
      swal2
        .fire({
          title: 'Are you sure?',
          text: 'This will add new script into the repository!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Add!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_MERGY_BACKEND + 'addScriptDetails',
              requestOptions
            )
              .then((response) => response.json())
              .then((data) => {
                this.setState({ apiResponse: data.statusCode })
                if (this.state.description == '') {
                  swal('Error!!', 'Please enter srcipt description !', 'error')
                } else if (this.state.scriptOwner.length <= 0) {
                  swal(
                    'Error!!',
                    'Please enter the amazon alias of the scriptOwner!',
                    'error'
                  )
                } else if (this.state.urlPattern.length <= 0) {
                  swal(
                    'Error!!',
                    'Please enter domain names mentioned as @match !',
                    'error'
                  )
                } else if (this.state.userSelectedTeams.length <= 0) {
                  swal(
                    'Error!!',
                    'Please enter applicable teams for the script!',
                    'error'
                  )
                } else if (this.state.fileName.length == 0) {
                  swal(
                    'Error!!',
                    'There is no script! Please select one!',
                    'error'
                  )
                } else if (duplicateCheck) {
                  swal('Error!!', 'Duplicate Error!', 'error')
                } else if (preventEditScript) {
                  swal('Error!!', 'Already Present!', 'error')
                } else {
                  this.fileUpload(files)
                }
              })
          } else {
            console.log('cancel')
          }
        })
    } else
      swal(
        'Error!!',
        'Script Already Exists! Please goto Edit section for modification',
        'error'
      )
  }

  render() {
    const {
      listOfTeam,
      urlPattern,
      defaultSelect,
      userSelectedTeams,
      listOfScript,
      scriptOwner,
    } = this.state

    console.log('Pattern', urlPattern)
    console.log('SelectedList', userSelectedTeams)
    console.log('Dup', listOfScript)

    return (
      <div className="AddConfigs">
        <div className="name">
          <h3 className="headerInline">Add New Script</h3>
          <div className="button headerInline headerMargin textSizeHeader">
            <button
              className="link-align disable-styling button-styling"
              onClick={this.onDiscard}
            >
              Discard
            </button>
            <button
              className="link-align enable-styling button-styling"
              onClick={this.onSubmitRequest}
              disabled={this.state.submitDisabled}
            >
              Save
            </button>
          </div>
        </div>

        <br />
        <hr />
        <form>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text textSize addNewStyling">
                Description:
              </span>
            </div>
            <textarea
              data-tip
              data-for="descriptionTip"
              onChange={(event) => this.handleChangeDesc(event)}
              value={this.state.description}
              class="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Provide a summary on what the script does"
              maxlength="256"
              aria-label="With textarea"
            ></textarea>
            &nbsp;&nbsp;
            <div className="input-group-prepend">
              <span className="input-group-text textSize addNewStyling">
                Script Owner:
              </span>
            </div>
            <textarea
              onChange={(event) => this.handleChangeScriptOwner(event)}
              value={this.state.scriptOwner}
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Provide the amazon alias on who owns the script"
              aria-label="With textarea"
            ></textarea>
          </div>
          <ReactTooltip id="descriptionTip" place="top" effect="solid">
            The maximum length of a description is 256 characters
          </ReactTooltip>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text textSize addNewStyling">
                URL Patterns:
              </span>
            </div>
            <textarea
              data-tip
              data-for="urlPatternTip"
              //onChange={(e) => this.change(e)}
              onChange={(event) => this.handleChangePattern(event)}
              type="text"
              name="col1"
              data-group="typeA"
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Enter the list of URLs applicable for the script separated by commas"
              aria-label="With textarea"
              value={this.state.urlPattern}
            ></textarea>
            <ReactTooltip id="urlPatternTip" place="top" effect="solid">
              Enter domain name mentioned as @match inside script
              [domain1,domain2]
            </ReactTooltip>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text textSize addNewStyling">
                Entitled Teams:
              </span>
              <Multiselect
                style={this.style}
                displayValue="label"
                options={listOfTeam}
                placeholder="Select the applicable teams from drop down that are required to use this script"
                showCheckbox
                onSelect={this.onSelect}
                onRemove={this.onRemove}
                ref={this.multiselectRef}
                style={{
                  multiselectContainer: {
                    color: 'black',
                  },
                  inputField: {
                    // To change input field position or margin
                    margin: '5px',
                    width: '100%',
                  },
                  searchBox: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    width: '922px',
                    height: '46px',
                  },
                }}
              />
            </div>
          </div>
          <input
            className="Center"
            type="file"
            accept=".js"
            ref={this.inputRef}
            name="selectedFile"
            onChange={(event) => this.onChange(event)}
          />
        </form>
      </div>
    )
  }
}
export default withRouter(AddScriptDetails)
