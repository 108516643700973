import React, { useState, useEffect } from 'react'
import '../../../src/style.css'
import { Button, Container, Input } from 'reactstrap'
import swal from 'sweetalert'
import swal2 from 'sweetalert2'
import axios from 'axios'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'

const TeamHandler = () => {
  const [listOfTeamName, setListOfTeamName] = useState([])
  const [listOfScript, setListOfScript] = useState([])
  const [newTeamName, setNewTeamName] = useState('')
  const [teamNameToBeDeleted, setTeamNameToBeDeleted] = useState('')
  const [duplicateError, setDuplicateError] = useState('')
  const [isPresentFlag, setIsPresentFlag] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [deleteDisabled, setDeleteDisabled] = useState(true)
  const [apiResponse, setApiResponse] = useState('')
  const [listOfTeams, setListOfTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([])

  useEffect(() => {
    loadTeamName()
  }, [])

  //util function to create options from api response
  const getFormatedData = (options) => {
    let result = []

    for (let i = 0; i < options.length; i++) {
      result.push({
        value: options[i],
        label: options[i],
      })
    }
    return result
  }
  //Get list of teams from api response
  const loadTeamName = async () => {
    const result = await axios.get(
      process.env.REACT_APP_MERGY_BACKEND + 'getListOfTeamNames'
    )
    setListOfTeamName(result.data)
    setListOfTeams(getFormatedData(result.data))
  }

  function splCharCheck(teamToAdd) {
    var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~0123456789]/
    if (format.test(teamToAdd)) return true
    else return false
  }
  //Event handler for Add Team
  const handleInput = (e) => {
    setNewTeamName(e.target.value.toUpperCase())
    if (splCharCheck(e.target.value.toUpperCase())) {
      setDuplicateError('Contain Special Character/Number!')
      setSubmitDisabled(true)
    } else if (listOfTeamName.includes(e.target.value.toUpperCase())) {
      setDuplicateError('Already exists!')
      setSubmitDisabled(true)
    } else if (e.target.value.length <= 0) {
      setSubmitDisabled(true)
      setDuplicateError('')
    } else {
      setSubmitDisabled(false)
      setDuplicateError('')
    }
  }
  //Event handler for Delete Team
  const handleInputDelete = (e) => {
    setTeamNameToBeDeleted(e.target.value)
    if (listOfTeamName.includes(e.target.value) === false) {
      setIsPresentFlag('No Such Team Exists')
      setDeleteDisabled(true)
    } else if (e.target.value.length <= 0) {
      setDeleteDisabled(true)
      setIsPresentFlag('')
    } else {
      setDeleteDisabled(false)
      setIsPresentFlag('')
    }
  }
  //Click Event handler for Add button
  const handleSave = (e) => {
    console.log('Button Clicked', e.target.value)
    let userInput = newTeamName
    console.log('Team Name User want to add', userInput)
    console.log('Existing Team', listOfTeamName)

    if (newTeamName.length > 0) {
      swal2
        .fire({
          title: newTeamName + '!',
          text: 'Are you sure to add this new team?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Add!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_MERGY_BACKEND + 'addTeam/' + newTeamName
            )
              .then((response) => response.json())
              .then((data) => {
                console.log(data)
                return data
              })
              .then((data) => setApiResponse({ data }))
            swal({
              text: 'You have successfully added new Team',
              type: 'success',
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + 'teamHandler'
              }
            })
          }
        })
    } else {
      swal({
        text: 'Please Add New Team Name',
        type: 'success',
      })
    }
  }
  //Click Event handler for Delete button
  const onDeleteTeam = (e) => {
    console.log('listOfTeam', listOfTeamName)
    if (selectedTeam.value) {
      swal2
        .fire({
          title: 'Remove Team!',
          text: 'Are you sure you want to remove team: ' + selectedTeam.value,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Remove!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            fetch(
              process.env.REACT_APP_MERGY_BACKEND +
                'deleteTeam/' +
                selectedTeam.value
            )
              .then((response) => response.json())
              .then((data) => {
                console.log(data)
                return data
              })
              .then((data) => setApiResponse({ data }))
            swal({
              text: 'You have successfully removed',
              teamNameToBeDeleted,
              type: 'success',
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + 'teamHandler'
              }
            })
          }
        })
    } else {
      swal({
        text: 'Please enter team name to be deleted',
        type: 'success',
      })
    }
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <div className="AddConfigs">
          <div className="name">
            <h3 className="headerInline">Team Handler </h3>
          </div>
        </div>

        <div id="cards_landscape_wrap-2">
          <div className="row">
            <div className="col-lg-5">
              <div class="card-flyer card1">
                <div class="text-box wrapper">
                  <div class="image-box">
                    <h6>ADD NEW TEAM</h6>
                  </div>
                  <div class="text-container">
                    <Input
                      className="form-control1"
                      data-tip
                      data-for="teamNameTip"
                      type="name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Team Name.."
                      onChange={handleInput}
                      maxLength={16}
                    />{' '}
                    <ReactTooltip id="teamNameTip" place="top" effect="solid">
                      Team Name can have only '-' as special character with max
                      length 16
                    </ReactTooltip>
                    <span style={{ color: 'yellow' }}>{duplicateError}</span>
                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={handleSave}
                      disabled={submitDisabled}
                    >
                      Add Team
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div class="card-flyer card1">
                <div class="text-box wrapper">
                  <div class="image-box ">
                    <h6>DELETE TEAM</h6>
                  </div>
                  <div class="text-container">
                    <Select
                      options={listOfTeams}
                      defaultValue={selectedTeam}
                      onChange={(e) => {
                        setSelectedTeam(e)
                        setDeleteDisabled(false)
                      }}
                      id="listOfDesignations"
                      isClearable={true}
                      placeholder="Select Team Name...."
                      maxMenuHeight={80}
                      menuPlacement="auto"
                    />

                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={onDeleteTeam}
                      disabled={deleteDisabled}
                    >
                      Delete Team
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
      </Container>
    </>
  )
}

export default TeamHandler
