import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { NavLink } from 'react-router-dom'
import UserName from '../Auth/UserName'
import * as FaIcons from 'react-icons/fa'
import UserAccess from '../Auth/UserAccess'
import UserId from '../Auth/UserId'

export default (props) => {
  const accessLevel = UserAccess()
  const currentUser = UserId()
  if (accessLevel === 'unknown') {
    return (
      <Menu className="bm-menu " {...props}>
        <li className="nav-item "></li>
      </Menu>
    )
  } else if (accessLevel === 'Admin') {
    return (
      <Menu className="bm-menu " {...props}>
        <li className="nav-item "></li>

        <li className="nav-item menuSpacing addNewStyling textSizeHeader">
          {' '}
          <FaIcons.FaUserCircle />
          <UserName />
        </li>
        <li className="nav-item addNewStyling textSizeHeader greyOut">
          <UserAccess />
        </li>

        <li className="nav-item menuSeprartor"></li>

        <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink className="nav-link bm-item" exact to="/Home">
            <FaIcons.FaUsers /> Home
          </NavLink>
        </li>
        <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink
            className="nav-link bm-item nav-item"
            exact
            to="/scriptDashboard"
          >
            <FaIcons.FaBookOpen /> Active Scripts
          </NavLink>
        </li>

        <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink
            className="nav-link bm-item nav-item"
            exact
            to="/disabledListOfScript"
          >
            <FaIcons.FaBookOpen /> Disabled Scripts
          </NavLink>
        </li>

        <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink
            className="nav-link bm-item nav-item"
            exact
            to="/addScriptDetails"
          >
            <FaIcons.FaFileSignature /> Add Script
          </NavLink>
        </li>
        <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink
            className="nav-link bm-item nav-item"
            exact
            to="/teamHandler"
          >
            <FaIcons.FaFileSignature /> Team Handler
          </NavLink>
        </li>

        <br></br>

        <li className="nav-item   textSizeSemiHeader">
          <a
            className="bm-item"
            href="https://tiny.amazon.com/b1e0xwnf"
            target="_blank"
          >
            {' '}
            <FaIcons.FaBug /> Report an issue
          </a>
        </li>

        <li className="nav-item menuSpacing textSizeSemiHeader">
          <a
            className="bm-item"
            href="https://w.amazon.com/bin/view/COPS_BLR_Tools_Automation/"
            target="_blank"
          >
            {' '}
            <FaIcons.FaInfoCircle /> About Us{' '}
          </a>
        </li>
      </Menu>
    )
  } else if (accessLevel === 'Tester') {
    return (
      <Menu className="bm-menu " {...props}>
        <li className="nav-item "></li>

        <li className="nav-item menuSpacing addNewStyling textSizeHeader">
          {' '}
          <FaIcons.FaUserCircle /> <UserName />
        </li>
        <li className="nav-item addNewStyling textSizeHeader greyOut">
          <UserAccess />
        </li>

        <li className="nav-item menuSeprartor"></li>

        {/* <li className="nav-item menuSeprartor textSizeHeader">
          <NavLink className="nav-link bm-item" exact to="/Home">
            <FaIcons.FaUsers /> Home
          </NavLink>
        </li> */}

        <br></br>
      </Menu>
    )
  }
}
